const authService = http => {
  const endpoint = `${process.env.VUE_APP_API_BASE_URL}auth/login`
  const post = data => http.post(endpoint, data)

  return {
    post,
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  authService,
}
