<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <div style="position:absolute; top: 10px">
          <LanguageSwitcher />
        </div>
        <b-col class="px-xl-2 mx-auto">
          <vuexy-logo class="mb-2" />
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('login.title_main') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('login.title_secondary') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                :label="`${$t('login.form.email')}`"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="user@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('login.form.password') }}</label>
                  <b-link :to="{ name: 'forgot-password' }">
                    <small>{{ $t('login.forgot_password') }}</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                {{ $t('login.form.button_submit') }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('login.text_register') }}</span>
            <br>
            <b-link
              :to="{ name: 'register' }"
              class="btn btn-dark"
            >
              <span>{{ $t('login.button_register') }}</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
          />
        </div>
      </b-col>
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>

/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { authService } from '@/services/auth/authService'
import LanguageSwitcher from '@/layouts/components/LanguageSwitcher.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VuexyLogo,
    LanguageSwitcher,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      authService: authService(this.$http),
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/bg_login.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          const data = await {
            email: this.userEmail,
            password: this.password,
          }
          return this.loginAction(data)
        }
      })
    },
    // recebe o objeto para o login
    async loginAction(data) {
      try {
        let resultado = await this.authService.post(data)
        resultado = await resultado.data.data

        this.$store.commit('users/makeUser', {
          token: resultado.token.accessToken,
          user: resultado.user,
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Login realizado com sucesso',
            text: `Bem-vindo ${resultado.user.name}`,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })

        if (resultado.user.kyc === 3) {
          this.$router.push({ name: 'dashboard' })
        } else {
          this.$router.push({ name: 'profile' })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao efetuar login.',
            text: error.response.data.message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.$router.push({ name: 'login' })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
